import { AxiosInstance } from "../helpers/AxiosInstance";
import Config from "../config";

const searchMessageLogs = (data) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Report/MessageLogs`, data);
}

const downloadMesageLogs = (data) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Report/MessageLogs/downloadZip`, data)
}

const getExportedMesageLogs = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Report/BackgroundProcess`)
}

const deleteMessagwlogReportfromDb = (Id: any) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Report/BackgroundProcess`, {
        params: {
            Id
        }
    });
};

const deleteBulkMessagwlogReportfromDb = (Ids: string[]) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Report/BackgroundProcess/MassActionDelete`, {
        data: [...Ids]
    });
};

export const MessageLogSevice = {
    searchMessageLogs,
    downloadMesageLogs,
    deleteMessagwlogReportfromDb,
    deleteBulkMessagwlogReportfromDb,
    getExportedMesageLogs
};