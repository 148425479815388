import Config from "../config";

export class TemplateModel {
    ID: string;
    Title: string;
    Message: string;
    Media?: MediaModel;
    UpdatedDateTime: string;
    CreatedByID: string;
    IsPrivate: boolean | -1;
}

export class MediaModel {
    ID: string;
    Name: string;
    Url: string;
    ContentType: string;
}

export const mapToTemplate = (x: any): TemplateModel => {
    return {
        ID: x.id,
        Title: x.title,
        Message: x.message,
        UpdatedDateTime: x.updatedDateTime,
        Media: x.media ? { ID: x.media?.id, Name: x.media?.name, ContentType: x.media?.contentType, Url: `${Config.API_URL}/api/File/${x.media?.id}/view` } as MediaModel : null,
        CreatedByID: x.createdById,
        IsPrivate:x.isPrivate,
    } as TemplateModel
}
