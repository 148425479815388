import Config from "../config";
import { UtilityService } from "../services/UtilityService";

export class ReportsModel {
    constructor() { }

    ID: string;
    TotalContacts: number;
    Groups: ReportGroupsModel[];
    SkippedContacts: string[];
    DeliveredContacts: string[];
    BouncedContacts: string[];
    AllContacts: string[];
    Message: string;
    Credits: number;
    PhoneNumber: string;
    Media: MediaModel;
    Sent: string;
    Type: number;
    ReportType: string;
    TotalRecords: number;
    Contacts: ReportContactModel[];
}
export class MediaModel {
    ID: string;
    Name: string;
    Url: string;
    ContentType: string;
}

export class ReportGroupsModel {
    GroupId: string;
    GroupName: string;
    Contacts: number;
    ContactsList: ReportContactModel[];
}

export class ReportContactModel {
    ContactId: string;
    Number: string;
    Name: string;
}

export const mapToReportContacts = (x: any): ReportContactModel => {
    return {
        ContactId: x.contactId,
        Number: x.number,
        Name: x.name
    }
}

export const mapToReportGroups = (x: any): ReportGroupsModel => {
    return {
        GroupId: x.groupId,
        GroupName: x.groupName,
        Contacts: x.contacts,
        ContactsList: x.contactsList ? x.contactsList.map(mapToReportContacts) : [],
    }
}

export const mapGroupContact = (groups: any): ReportContactModel[] => {
    let contacts = [];
    groups.map(g => {
        if (g.ContactsList) {
            contacts = contacts.concat(g.ContactsList);
        }
    });
    return contacts;
}

export const mapToReport = (x: any): ReportsModel => {
    const contacts = !!x.contacts && x.contacts.map((x) => x.number)
    const groups = x?.groups.flatMap((x) => x?.contactsList?.map(y => y.number))
    const notSent = [...contacts, ...groups]?.filter(z => (!x.skippedContacts.includes(z) && !x.deliveredContacts?.includes(z) && !x.bouncedContacts?.includes(z)))
    const allContacts = [...x.groups ? x.groups.map(mapToReportGroups)?.flatMap(m => m?.ContactsList) : [], ...x.contacts ? [...x.contacts.map(mapToReportContacts)] : []]
    return {
        ID: x.id,
        TotalContacts: x.totalContacts,
        Groups: x.groups ? x.groups.map(mapToReportGroups) : null,
        SkippedContacts: x.skippedContacts ? x.skippedContacts : [],
        DeliveredContacts: x.deliveredContacts ? x.deliveredContacts : [],
        BouncedContacts: x.bouncedContacts ? x.bouncedContacts : [],
        Message: x.body,
        Credits: x.credits,
        PhoneNumber: x.phoneNumber,
        Media: x.media ? { ID: x.media?.id, Name: x.media?.name, ContentType: x.media?.contentType, Url: `${Config.API_URL}/api/File/${x.media?.id}/view` } as MediaModel : null,
        Sent: x.createdDateTime,
        Type: x.typeId,
        ReportType: x.typeId === 1 ? "Broadcast" : x.groups.length === 0 && x.totalContacts === 1 ? " Scheduled" : "Scheduled Broadcast",
        TotalRecords: x.totalRecords,
        Contacts: x.contacts ? [...x.contacts.map(mapToReportContacts)] : null,
        AllContacts: allContacts.map(x => x.Number),
    } as ReportsModel
}

export const getNameString = (Reports?: ReportsModel): string => {

    if (!!Reports.Groups && Reports.Groups.length >= 1) {
        let count = Reports.TotalContacts - Reports.Groups[0].Contacts;
        return ` ${Reports.Groups[0].GroupName} ${count > 1 ? `${count} Others` : count === 1 ? `${count} other` : ""}`.trim();
    }
    else {

        let count = Reports.TotalContacts - 1;
        return `${!!Reports.Contacts[0].ContactId !== null ? Reports.Contacts[0].Name ? Reports.Contacts[0].Name : UtilityService.maskPhoneNumberOnLength(Reports.Contacts[0].Number) : ""} ${count ? "+" : ""} ${count > 1 ? `${count} Others` : count === 1 ? `${count} Other` : ""}`.trim();
    }
}

export enum ReportPhoneNumberStatus {
    Delivered = "Delivered",
    NotSent = "Not Sent",
    Failed = "Failed",
    Sent = "Sent"
}
