import { Role } from "./roles.model";

export class UserModel {
    constructor() { }

    ID: string;
    CreatedDateTime: string;
    Email: string;
    FirstName: string;
    ImageReferenceID: string;
    IsDeleted: boolean;
    LastLoggedIn: string;
    LastName: string;
    OrganizationID: string;
    Password: string;
    PhoneNumber: string;
    PhoneNumberID: string;
    RoleID: Role;
    UserStatus: number;
}

export enum UserStatus {
    ACTIVE = 1,
    LOCKED,
    PENDING,
}

