export interface MessageLogExportModel {
    status:          Status;
    data:            null | string;
    type:            string;
    errorMessage:    null | string;
    id:              string;
    documentType:    string;
    isDeleted:       boolean;
    createdDateTime: Date;
    createdById:     string;
    updatedDateTime: Date;
    updatedById:     any;
}

export enum Status {
    Completed = "Completed",
    Inprogress = "InProgress",
    Fail = "Fail"
}

export const mapToMessageLogExportModel = (x: any): MessageLogExportModel => {
    if (!x.isDeleted) {
        return {
            status: x.status,
            data: x.data,
            type: x.type,
            errorMessage: x.errorMessage,
            id: x.id,
            documentType: x.documentType,
            isDeleted: x.isDeleted,
            createdDateTime: x.createdDateTime,
            createdById: x.createdById,
            updatedDateTime: x.updatedDateTime,
            updatedById: x.updatedById,
        } as MessageLogExportModel
    }  
}