import { AxiosInstance } from "../helpers/AxiosInstance";
import Config from "../config";


const getReports = (pages, pageSize) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/Audit/${pages}?pageSize=${pageSize}`);
}

const getCreditLogs = (date: string, orgId: string) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Report/MonthlyCreditLogs/${orgId}?month=${date}`);
}

const createReport = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Report`, data);
}

const updateReport = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Report`);
}

export const ReportService = {
    getReports,
    getCreditLogs,
    createReport,
    updateReport
};