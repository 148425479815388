import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';

const getKafkaOrganiztons = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Organizations/OrganizationGroup`);
};

const AssignGroups = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Organizations/OrganizationGroup`, data);
};

export const KafkaService = {
    getKafkaOrganiztons,
    AssignGroups,
};

