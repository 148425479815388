import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';
import { mapToProfile } from '../models/profile.model';
import { AuthService } from './AuthService';
import { UtilityService } from './UtilityService';


const getUsers = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Users`);
};

const getRoles = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Users/Roles`);
};

const getCurrentUser = async () => {
    // var user = localStorage.getItem("current-user")?.toString();
    // if (!!user && !fromApi)
    //     return new Promise((resolve, reject) => {
    //         setTimeout(function () {
    //             resolve({ data: JSON.parse(user!) });
    //         }, 1);
    //     });    

    try {
        const res = await AxiosInstance.get(`${Config.API_URL}/api/Users/current-user?timeZone=${UtilityService.getDefaultTimeZone()?.Name}`);
        localStorage.setItem('current-user', JSON.stringify(res.data));
        return res;
    } catch (error) {
        AuthService.logout();
    }
};

const getCurrentUserFromLocalStorage = () => {
    if (localStorage.getItem("current-user") !== null) {
        var user = JSON.parse(localStorage.getItem("current-user")?.toString());
        return mapToProfile(user);
    }
    else {
        AuthService.logout();
    }
}

const getUserById = (id: any) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Users/${id}`);
};

const updateUser = (data: any) => {
    if (!!data.roleId)
        data.roleId = data.roleId + '';

    return AxiosInstance.put(`${Config.API_URL}/api/Users`, data);
};

const createUser = (data: any) => {
    if (!!data.roleId)
        data.roleId = data.roleId + '';

    return AxiosInstance.post(`${Config.API_URL}/api/Users`, data);
};

const deleteUser = (id: any) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Users/${id}`);
};

const updateUserStatus = (id: any) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Users/Disable/${id}`);
};

const getLoggedInUserRole = () => {
    var userString = localStorage.getItem("current-user");
    if (userString) {
        var user = JSON.parse(userString?.toString()!);
        return user && user.roleId ? parseInt(user.roleId) : 0;
    }
}

const hasSysAdminRole = () => {
    var user = JSON.parse(localStorage.getItem("current-user")?.toString()!);
    return user.roleId == 1;
}

const hasOrgAdminRole = () => {
    var user = JSON.parse(localStorage.getItem("current-user")?.toString()!);
    return user.roleId == 2;
}

const hasUserRole = () => {
    var user = JSON.parse(localStorage.getItem("current-user")?.toString()!);
    return user.roleId == 3;
}

const getUserPhoneNumberId = () => {
    var user = JSON.parse(localStorage.getItem("current-user")?.toString()!);
    return user.phoneNumberId;
}

const getUserPhoneNumber = () => {
    var user = JSON.parse(localStorage.getItem("current-user")?.toString()!);
    return user.phoneNumber;
}


const getCurrentUserOrgId = () => {
    var user = JSON.parse(localStorage.getItem("current-user")?.toString()!);
    return user.organizationId;
}

const forgotPassword = (email: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Users/Forgot_Password?email=${email}`);
};

const resetPassword = (token: any, password: any) => {
    if (!!token)
        return AxiosInstance.patch(`${Config.API_URL}/api/Users/Reset_Password?token=${token}`, { password: password, timeZone: UtilityService.getDefaultTimeZone()?.Name });
    return AxiosInstance.patch(`${Config.API_URL}/api/Users/Reset_Password`, { password: password, timeZone: UtilityService.getDefaultTimeZone()?.Name });
};

const validateResetpassword = (token: any) => {
    if (!!token)
        return AxiosInstance.patch(`${Config.API_URL}/api/Users/Validate_Reset_Password?token=${token}`);
}

const changeProfile = (data: any, imageCleared: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Users/Change-Profile?imageCleared=${imageCleared}`, data);
};

const changePassword = (data: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Users/Change-Password`, data);
};

const verifyTwoFactAuth = (otpPin: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Users/Verify-TwoFactAuth/${otpPin}`);
}

const sendTwoFactAuthPin = () => {
    return AxiosInstance.post(`${Config.API_URL}/api/Users/SendTwoFactAuthPin`);
}

// const accessEmojis() =>{
//     var CurrentUser = JSON.parse(localStorage.getItem("current-user")?.toString() || '{}')
//     if (!CurrentUser.planData) {
//         return true
//     }
//     else {
//         return CurrentUser.planData.showEmojis;
//     }
// }

// const accessMMS() =>{
//     var CurrentUser = JSON.parse(localStorage.getItem("current-user")?.toString() || '{}')
//     if (!CurrentUser.planData) {
//         return true
//     }
//     else {
//         return CurrentUser.planData.allowMMS
//     }
// }

// const accessGroups()=> {
//     var CurrentUser = JSON.parse(localStorage.getItem("current-user")?.toString() || '{}')
//     if (!CurrentUser.planData) {
//         return true
//     }
//     else {
//         return CurrentUser.planData.allowGroupTexting
//     }
// }

const usersMassAction = (action: string, data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Users/MassAction?action=${action}`, data);
}

const reinviteUser = (userID: string) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Users/reinvite?userId=${userID}`);
}

const getCurrentUserId = () => {
    return JSON.parse(localStorage.getItem("current-user") ?? "").id
}

const getLoggedInUserPlanMetrics = () => {
    var userString = localStorage.getItem("current-user");
    if (userString) {
        var user = JSON.parse(userString?.toString()!);
        return user && !!user.planTracker ? user.planTracker : "";
    }
}


export const UserService = {
    getUsers,
    getRoles,
    getUserById,
    getUserPhoneNumberId,
    getUserPhoneNumber,
    getCurrentUserOrgId,
    updateUser,
    createUser,
    deleteUser,
    updateUserStatus,
    getCurrentUser,
    hasSysAdminRole,
    hasOrgAdminRole,
    hasUserRole,
    forgotPassword,
    resetPassword,
    validateResetpassword,
    changeProfile,
    changePassword,
    verifyTwoFactAuth,
    sendTwoFactAuthPin,
    getLoggedInUserRole,
    usersMassAction,
    reinviteUser,
    getCurrentUserId,
    getCurrentUserFromLocalStorage,
    getLoggedInUserPlanMetrics
}