export class KafkaModel {
    GroupId: number;
    Organizations: OrgModel[];
}

export class OrgModel {
    ID: string;
    IsDeleted: boolean;
    Name: string;
}

export const mapToOrg = (organizations: any): OrgModel => {
    return {
        ID: organizations.id,
        IsDeleted: organizations.isDeleted,
        Name: organizations.name
    } as OrgModel
}

export const mapToKafka = (x: any): KafkaModel => {
    return {
        GroupId: x.groupId,
        Organizations: x.organizations ? x.organizations.map(mapToOrg) : []
    } as KafkaModel
}