import { Node } from "slate";

export class AlertNotificationModel {
    constructor() {
    }
    ID: string;
    Type: ReleaseAlertType;
    Title: string;
    SubTitle: string;
    Description: Node[];
    LinkType: AlertsLinkType;
    DisplyText: string;
    Link: string;
    StartDateTime: number;
    EndDateTime: number;
    Occurrence: number;
    Status: AlertStatus;
    IsDeleted: boolean;
}

export const mapToAlertNotification = (x: any): AlertNotificationModel => {
    return {
        ID: x.id,
        Type: x.type as ReleaseAlertType,
        Title: x.title,
        SubTitle: x.subTitle,
        Description: JSON.parse(x.description),
        LinkType: x.linkType as AlertsLinkType,
        DisplyText: x.displyText,
        Link: x.link,
        StartDateTime: x.startDateTime,
        EndDateTime: x.endDateTime,
        Occurrence: x.occurrence,
        Status: x.status as AlertStatus,
        IsDeleted: x.isDeleted,


    } as AlertNotificationModel
}


export enum AlertStatus {
    SCHEDULED = 1,
    COMPLETED,
    STOPPED,
}

export enum AlertsLinkType {
    ALERTLINK = 1,
    ALERTBUTTON
}

export enum ReleaseAlertType {
    Release = 1
}