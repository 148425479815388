import axios from "axios";
import { AuthService } from "../services/AuthService";
import { UserService } from "../services/UserService";


export const AxiosInstance = axios.create();

AxiosInstance.interceptors.request.use(
    req => {
        var isTokenExpired = AuthService.isTokenExpired();

        if (isTokenExpired && req.method !== "OPTIONS") {
            return AuthService.refreshToken().then(res => {
                req.headers.authorization = `Bearer ${AuthService.getToken().access_token}`;
                return Promise.resolve(req);
            }).catch(err => {
                console.log(err);
                AuthService.logout()
            });
        } else {
            var token = AuthService.getToken();
            if (!!token && !!token.access_token && req.method !== "OPTIONS")
                req.headers.authorization = `Bearer ${token.access_token}`;
            if (!!localStorage.getItem("current-user") && !!UserService.getUserPhoneNumber() && !!UserService.getUserPhoneNumberId()) {
                req.headers.PhoneNumber = UserService.getUserPhoneNumber()
                req.headers.PhoneNumberId = UserService.getUserPhoneNumberId()
            }
            return req;
        }
    },
    error => {
        return Promise.reject(error);
    }
);

AxiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log(error);

        if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
            AuthService.logout();
        }

        return Promise.reject(error);
    }
);
