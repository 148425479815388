import { useLottie } from "lottie-react";
import { FunctionComponent, HTMLAttributes } from "react";
import animationData from "./fallback-animation.json";

interface IProps extends HTMLAttributes<HTMLDivElement> { }

const FallbackUIComponent: FunctionComponent<IProps> = (props: IProps) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const { View } = useLottie(defaultOptions);

    return <>
        <div className={props.className} style={{ display: "flex", height: "100vh", background: "#f0ebef" }}>
            <div style={{ margin: "auto", width: "16rem" }}>
                {/* <Lottie options={defaultOptions}></Lottie> */}
                {View}
            </div>
        </div>
    </>;
}
export default FallbackUIComponent;