import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';


const getAllSystemAdminAlertNotifications =() =>{
    return AxiosInstance.get(`${Config.API_URL}/api/Notifications`);
};

const createAlertNotification = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Notifications`, data);
};

const updateAlertNotification = (data: any) => {
    return AxiosInstance.put(`${Config.API_URL}/api/Notifications`, data);
};

const deleteAlertNotification = (id: any) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Notifications/${id}`);
};

const stopOrResumeAlertNotification = (id: any, data: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Notifications/${id}`, data);
}

const getAllUserAlertNotifications = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Notifications/User`);
};

const closeAlertNotification = (id: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Notifications/${id}/Close`);
};

export const AlertNotificationService = {
    getAllSystemAdminAlertNotifications,
    createAlertNotification,
    updateAlertNotification,
    deleteAlertNotification,
    stopOrResumeAlertNotification,
    getAllUserAlertNotifications,
    closeAlertNotification,
};
